import { useEffect, useState } from "react";
import LoadingSpinner from "../common/UI/loadingSpinner/LoadingSpinner";
import { DataTable } from "../common/UI/table/dataTable";
import { ICommonReturnType, IGamesTrendingProps } from "../../interfaces/interfaceCommon";
import config from "../../constants/config";
import getService from "../../services/getService";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import postService from "../../services/post";
import toast from "react-hot-toast";

export const PopularGames = () => {
    const [rowData, setRowData] = useState<IGamesTrendingProps[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [updatedItems, setUpdatedItems] = useState<{ id: number, isPopular: boolean }[]>([]);

    // State to manage the checked status of each row
    const [checkedState, setCheckedState] = useState<{ [key: number]: boolean }>({});

    useEffect(() => {
        async function fetchAllGamesTrending() {
            setIsDataLoading(true);
            try {
                const url = `${config.cmsConfig.apiEndpoint.getAllPopularGames}/15`;
                const response: ICommonReturnType = await getService(url);
                const data = response.data;

                // Initialize the checked state
                const initialCheckedState = data.reduce((acc: any, row: IGamesTrendingProps) => {
                    acc[row.id] = row.isPopular;
                    return acc;
                }, {});
                setCheckedState(initialCheckedState);

                setRowData(data);
            } catch (error) {
                console.log("Failed to load fixtures data");
            } finally {
                setIsDataLoading(false);
            }
        }

        fetchAllGamesTrending();
    }, []);

    const handleCheckboxChange = (id: number) => {
        setCheckedState((prevState) => {
            const newCheckedState = !prevState[id];
            const updatedState = { ...prevState, [id]: newCheckedState };

            // Update the array of unique objects
            setUpdatedItems((prevItems) => {
                const updatedArray = prevItems.filter(item => item.id !== id);
                return [...updatedArray, { id, isPopular: newCheckedState }];
            });

            return updatedState;
        });
    };

    const createColumns = () => {
        const columns: GridColDef[] = [
            {
                field: 'id',
                headerName: 'ID',
                resizable: false,
                headerAlign: "center",
                align: "center"
            },
            {
                field: 'GameName',
                headerName: 'Games Name',
                resizable: false,
                flex: 1,
                headerAlign: "center",
                align: "center"
            },
            {
                field: 'Provider',
                headerName: 'Provider Name',
                  
                resizable: false,
                flex: 1,
                headerAlign: "center",
                align: "center"
            },
            {
                field: 'isPopular',
                headerName: 'Popular',
                resizable: false,
                flex: 1,
                headerAlign: "center",
                align: "center",
                renderCell: (params: any) => {
                    return (
                        <Checkbox
                            checked={!!checkedState[params.row.id]}
                            onChange={() => handleCheckboxChange(params.row.id)}
                            inputProps={{ 'aria-label': 'Popular Checkbox' }}
                        />
                    );
                }
            },
        ];
        return columns;
    };

    const handleActionButtonClick = async () => {
        const body = {
            popular: updatedItems,
            wlId: 15
        }
        const url = `${process.env.REACT_APP_API_LINK}${config.cmsConfig.apiEndpoint.updatePopularGames}`;
        const result = await postService(url, body);
        setRowData(result.data);
        toast.success(result.message);
    }


    return (
        <div>
            {isDataLoading ? (
                <LoadingSpinner />
            ) : (
                <DataTable
                    rowData={rowData}
                    columns={createColumns()}
                    rowsPerPageOptions={[100,25]}
                    shouldShowDatePicker={false}
                    shouldShowSearch={true}
                    shouldShowActionButton={true}
                    actioButtonText="Set Popular"
                    onClickActionButton={handleActionButtonClick}
                />
            )}
        </div>
    );
};
