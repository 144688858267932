import React, { useEffect, useState } from "react";
import { Tooltip, Switch, FormControlLabel } from "@mui/material";
import { ICommonReturnType } from "../../../../../interfaces/interfaceCommon";
import getService from "../../../../../services/getService";
import config from "../../../../../constants/config";

function Maintenance() {
  const [maintenance, setMaintenance] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const toggleMaintenance = async () => {
    const newMode = !maintenance;
    setMaintenance(newMode);

    try {
      const url = `${config.cmsConfig.apiEndpoint.updateMaintenance}?mode=${newMode}`;
      await getService(url);
    } catch (error) {
      console.error("Failed to update maintenance mode", error);
    }
  };

  useEffect(() => {
    async function fetchMaintenance() {
      setIsDataLoading(true);
      try {
        const url = `${config.cmsConfig.apiEndpoint.getMaintenance}`;
        const response: ICommonReturnType = await getService(url);
        const data = response.data;

        if (data && data.configValue !== undefined) {
          setMaintenance(data.configValue === "true" || data.configValue === true);
        }
      } catch (error) {
        console.log("Failed to load maintenance data");
      } finally {
        setIsDataLoading(false);
      }
    }

    fetchMaintenance();
  }, []);

  return (
    <Tooltip title="Toggle Maintenance Mode">
      <FormControlLabel
        control={<Switch checked={maintenance} onChange={toggleMaintenance} disabled={isDataLoading} />}
        label={maintenance ? "Maintenance On" : "Maintenance Off"}
      />
    </Tooltip>
  );
}

export default Maintenance;
